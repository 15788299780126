const API_URL = () => {

  // 如果當前是瀏覽器需要 API_URL
  if (typeof window !== 'undefined') {
    if (window.location.hostname === 'www.hoost.tw')
      return 'https://www.hoost.tw/api/v1';
    return 'https://dev.hoost.tw/api/v1';
  }

  // 如果當前是 NGINX 內的 SERVER 端需要 API_URL
  if (process.env.USE_NGINX) return 'http://nginx/api/v1';

  // 如果當前是 production 環境的 SERVER 端需要 API_URL，但不走 NGINX
  if (process.env.ENV === 'production') return 'https://www.hoost.tw/api/v1';

  // 不符合任何情況，當前可能處於開發環境
  return 'https://dev.hoost.tw/api/v1';
};

const currentEnv = () => {
  switch (process.env.ENV) {
    case 'localDev':
      return 'localDev';
    case 'development':
      return 'development';
    default:
      return 'production';
  }
};

const HOST = () => {
  switch (currentEnv()) {
    case 'localDev':
      return 'localhost:7001';
    case 'development':
      return 'dev.hoost.tw';
    case 'production':
      return 'www.hoost.tw';
  }
};

const config = {
  API_URL: API_URL(),
  HOST: HOST(),
  DEFAULT_PAGE_TITLE: 'Hoost Course - 線上語音課程及 Podcast - 成為終身學習者',
  DEFAULT_PAGE_DESCRIPTION:
    'Hoost 的使命是促進分享、連結、繽紛的語音學習生態，我們希望你在睡前、運動、通勤、或是進行制式工作時聆聽，尤其是聽進腦中、留存心底，讓你不會被各類視覺特效分散了學習的專注力，導致缺少了思考及內化，和知識精華擦身而過。',
  DEFAULT_PAGE_IMAGE_URL: 'https://cdn.hoost.tw/banner.jpg',
  DEFAULT_PAGE_URL: currentEnv() !== 'production' ? 'https://dev.hoost.tw'
    : 'https://www.hoost.tw',
  APP_STORE_APPLE: 'https://apps.apple.com/us/app/id1536171448',
  APP_STORE_ANDROID: 'market://details?id=tw.hoost.podcast',
  API_AUTH_TOKEN: currentEnv() === 'production'
    ? 'tgXxw9DctEyhQkYxAncbDcrP'
    : '2TUwayLoiuKCGM92x8kCiKib',
  SITEMAP_AUTH_TOKEN: 'tgXxw9DctEyhQkYxAncbDcrP',
  GOOGLE_API_KEY:
    currentEnv() === 'production'
      // eslint-disable-next-line max-len
      ? '823223159033-iksqv065c0323c0v5ogts8fmh8lhdlmm.apps.googleusercontent.com'
      // eslint-disable-next-line max-len
      : '136689384278-lkbea1b4au5nos02moc9lui5b1uu9k2d.apps.googleusercontent.com',
  GOOGLE_CLIENT_SECRET:
    currentEnv() === 'production'
      ? 'OygI_KHWoi4H9p89ncKNdOAF'
      : 'weJ-X_HTkHrop4qKgdimvHak',
  FB_API_KEY:
    currentEnv() === 'production'
      ? '2378573225773772'
      : '255431225727566',
  FB_CLIENT_SECRET:
    currentEnv() === 'production'
      ? '49ad57e000ce955adbce846e68b69ead'
      : 'c9d748876d144e5940027f61b3c2ef82',
  APPLE_API_KEY:
    currentEnv() === 'production'
      ? 'www.hoost.tw'
      : 'dev.hoost.tw',
};

export default config;
