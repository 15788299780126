import config from '../../config';
import Episode from './Episode';
import { Podcast } from './Podcast';
import { Topic } from './Topic';
import User from './User';

interface AppStateType {
  topBar?: {};
  SideNav?: {
    isOpened: boolean;
  };
  EpisodeDetailPage?: {
    episodeData: Episode | undefined;
  };
  TopicDetailPage?: {
    topicData: Topic | undefined;
    podcastData: Podcast | undefined;
    entryEpisodeData: Episode | undefined;
  };
  TopicListPage?: {
    topics: Topic[];
  };
  APIKeys?: {
    googleAPIKey: string;
  };
  ErrorPage?: {
    active: boolean;
    title: string;
    description: string;
  };
  Session?: {
    authToken: string | undefined;
    userData: User | undefined;
  };
  LoginResponse?: {
    login: boolean;
    returnURL: string | undefined;
  };
  TopicBuyPage?: {
    topic: Topic;
  };
  PodcastPage?: {
    podcast: Podcast;
    topics: Topic[];
    episodes: Episode[];
  };
  ListenHistory: { episodeId: string; time: number; total: number }[];
}

export const emptyState: AppStateType = {
  APIKeys: { googleAPIKey: config.GOOGLE_API_KEY },
  EpisodeDetailPage: { episodeData: undefined },
  TopicDetailPage: {
    entryEpisodeData: undefined,
    podcastData: undefined,
    topicData: undefined,
  },
  SideNav: { isOpened: false },
  topBar: {},
  ErrorPage: {
    active: false,
    title: '您可能迷路了',
    description: '我們找不到您要的頁面 ...',
  },
  TopicListPage: { topics: [] },
  Session: {
    authToken: undefined,
    userData: undefined,
  },
  LoginResponse: {
    login: false,
    returnURL: '',
  },
  TopicBuyPage: { topic: undefined },
  PodcastPage: {
    podcast: undefined,
    topics: [],
    episodes: [],
  },
  ListenHistory: [],
};

export default AppStateType;
