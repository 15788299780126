import Link from 'next/link';
import React from 'react';

const Footer = () => {
  return (
    <div
      className="bg-grey-900 w-full py-12 flex flex-col
     justify-center px-8 lg:px-16 items-center">
      <div className="container flex flex-col md:flex-row justify-between">
        <div>
          <Link href="/">
            <img
              src={'/icons/hoost-logo.svg'}
              className="h-9 cursor-pointer"
              loading="lazy"
              alt=""
            />
          </Link>
          <div
            className="flex flex-row w-full justify-evenly
            mt-8 lg:justify-start"
          >
            <div className="h-12">
              <img
                className="w-full cursor-pointer"
                src={'/images/download ios.png'}
                onClick={() => {
                  if (window?.fbq) window.fbq('track', 'iOSdownload');
                  window.location.href = 'https://download.hoost.tw/vA72?pid=hoost&c=footer-iOS&utm_source=hoost&utm_medium=footer-iOS&utm_campaign=download';
                }}
                alt=""
              />
            </div>
            <div className="h-12 lg:ml-4">
              <img
                className="w-full cursor-pointer"
                src={'/images/download android.png'}
                onClick={() => {
                  if (window?.fbq) window.fbq('track', 'AndroidDownload');
                  window.location.href = 'https://download.hoost.tw/vA72?pid=hoost&c=footer-Android&utm_source=hoost&utm_medium=footer-Android&utm_campaign=download';
                }}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col w-1/2 justify-evenly">
          <div className="mt-8 md:mt-0">
            <p className="text-amber-200-accent font-bold">追蹤動態</p>
            <div className="text-white text-sm mt-4">
              <a href="https://www.hoost.tw/blog/" target="_blank" className="hover:no-underline" rel="noreferrer">
                <p className="mb-2 cursor-pointer">Blog</p>
              </a>
              <a href="https://line.me/R/ti/p/%40hoost" target="_blank" className="hover:no-underline" rel="noreferrer">
                <p className="mb-2 cursor-pointer">LINE 終身學習者社群</p>
              </a>
              <a
                href="https://www.facebook.com/Hoostapp"
                target="_blank"
                className="hover:no-underline"
                rel="noreferrer">
                <p className="mb-2 cursor-pointer">Facebook</p>
              </a>
              <a
                href="https://www.instagram.com/hoost.tw/"
                target="_blank"
                className="hover:no-underline"
                rel="noreferrer">
                <p className="mb-2 cursor-pointer">Instagram</p>
              </a>
            </div>
          </div>
          <div className="mt-8 md:mt-0">
            <p className="text-amber-200-accent font-bold">更多資訊</p>
            <div className="text-white text-sm mt-4">
              <a href="https://help.hoost.tw/" target="_blank" className="hover:no-underline" rel="noreferrer">
                <p className="mb-2 cursor-pointer">使用說明</p>
              </a>
              <a href="https://creator.hoost.tw/" target="_blank" className="hover:no-underline" rel="noreferrer">
                <p className="mb-2 cursor-pointer">播客資訊專區</p>
              </a>
            </div>
          </div>
          <div className="mt-8 md:mt-0">
            <p className="text-amber-200-accent font-bold">聯絡我們</p>
            <div className="text-white text-sm mt-4">
              <a href="mailto:contact@hoost.tw" className="hover:no-underline">
                <p className="mb-2 cursor-pointer">客服信箱</p>
              </a>
              <a
                href="https://line.me/R/ti/p/%40wrl3894n"
                target="_blank"
                className="hover:no-underline"
                rel="noreferrer">
                <p className="mb-2 cursor-pointer">LINE 客服</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="border-t border-grey-800 mt-8 pt-4 container
       text-white w-full text-sm lg:flex lg:flex-row lg:justify-between"
      >
        <div className="mb-4 lg:flex lg:flex-row">
          <a
            href="https://help.hoost.tw/contract/terms"
            target="_blank"
            className="hover:no-underline"
            rel="noreferrer">
            <p className="mb-2 lg:mr-4 cursor-pointer">使用條款</p>
          </a>
          <a
            href="https://help.hoost.tw/contract/privacy"
            target="_blank"
            className="hover:no-underline"
            rel="noreferrer">
            <p className="mb-2 lg:mr-4 cursor-pointer">隱私權政策</p>
          </a>
          <a
            href="https://help.hoost.tw/contract/community"
            target="_blank"
            className="hover:no-underline"
            rel="noreferrer">
            <p className="mb-2 lg:mr-4 cursor-pointer">社群規範</p>
          </a>
          <a
            href="https://help.hoost.tw/contract/paying-user-agreement"
            target="_blank"
            className="hover:no-underline"
            rel="noreferrer">
            <p className="mb-2 lg:mr-4 cursor-pointer">課程訂購暨服務合約</p>
          </a>
        </div>
        <div>
          <p className="opacity-50">© Hoost, Inc. 2021</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
