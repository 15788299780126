import { GetServerSidePropsContext, NextPageContext } from 'next';

function appendSetCookieHeader(context: GetServerSidePropsContext
  | NextPageContext, cookieName: string, cookieValue: string, maxAge: number) {

  const oldCookies = context.res.getHeaders()['set-cookie'];

  if (Array.isArray(oldCookies)) {
    context.res.setHeader('set-cookie', [
      ...oldCookies,
      `${cookieName}=${cookieValue}; Max-Age=${maxAge}`
    ]);
    return;
  }

  if (typeof oldCookies === 'string') {
    context.res.setHeader('set-cookie', [
      oldCookies,
      `${cookieName}=${cookieValue}; Max-Age=${maxAge}`
    ]);
    return;
  }

  context.res.setHeader('set-cookie',
    `${cookieName}=${cookieValue}; Max-Age=${maxAge}`);

}

export default appendSetCookieHeader;
