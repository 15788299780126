import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSession from '../../hooks/useSession';
import AppStateType from '../../models/AppStateType';
import { closeSideNavAction } from '../../redux/action';
import Link from 'next/link';

const SideNav = () => {
  const isOpened = useSelector<AppStateType, boolean>(
    (s) => s.SideNav.isOpened
  );
  const dispatch = useDispatch();
  const [loginReturnUrl, setLoginReturnUrl] = useState('');
  const session = useSession();

  useEffect(() => {
    setLoginReturnUrl('?url=' + window.location.href);
  }, []);

  function handleDownloadClick() {
    window.open('https://download.hoost.tw/vA72?pid=hoost&c=header&utm_source=hoost&utm_medium=header&utm_campaign=download', '_blank').focus();
  }


  return (
    <div className={'sidenav ' + (isOpened ? 'active' : 'inactive')}>
      <div
        className="backdrop"
        onClick={() => dispatch(closeSideNavAction())}
      />
      <div className="sidenav-inner pt-28 pb-8">

        <p
          onClick={handleDownloadClick}
          className="text-xl
            text-white font-bold text-center mt-3"
        >
          免費下載 Hoost
        </p>

        <p
          className="text-xl
            text-white font-bold text-center mt-3"
        >
          在 Hoost 授課
        </p>

        <Link href={session.isLoggedIn ? '/user/topics' : '/login'}>
          <p
            className="text-xl
            text-white font-bold text-center mt-3"
          >
            我的課程
          </p>
        </Link>

        {session.isLoggedIn ? <p
          onClick={session.logout}
          className="text-xl
            text-white font-bold text-center mt-3"
        >
           登出
        </p>
          : <Link href={`/login?url=${loginReturnUrl}`}>
            <p
              onClick={() => dispatch(closeSideNavAction())}
              className="text-xl
            text-white font-bold text-center mt-3"
            >
            登入
            </p>
          </Link>
        }
      </div>
    </div>
  );
};

export default SideNav;
