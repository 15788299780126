import useSession from '@hooks/useSession';
import useLocalStorage from '@hooks/useStorage';
import App, { AppContext, AppProps } from 'next/app';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import React, { PropsWithChildren, useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { createStore } from 'redux';
import { loadListenHistoriesAction } from 'src/redux/action';
import Footer from '../src/components/Footer';
import SideNav from '../src/components/SideNav';
import TopBar from '../src/components/TopBar';
import { reducer } from '../src/redux/reducer';
import '../styles/globals.sass';
import 'tailwindcss/tailwind.css';
import '../styles/index.css';
import appendSetCookieHeader from '@helpers/appendSetCookieHeader';

const store = createStore(reducer);

function MyApp({
  Component,
  pageProps
}: AppProps) {
  const router = useRouter();

  useEffect(() => {
    let routeChangeStart = () => NProgress.start();
    let routeChangeComplete = () => NProgress.done();

    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('routeChangeComplete', routeChangeComplete);
    router.events.on('routeChangeError', routeChangeComplete);
    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('routeChangeComplete', routeChangeComplete);
      router.events.off('routeChangeError', routeChangeComplete);
    };
  }, []);

  function disableVerticalScroll() {
    if (window.location.pathname.includes('topic') &&
      window.location.pathname.includes('listen'))
      document.body.style.overflow = 'hidden';
    else {
      document.body.style.overflow = 'auto';
    }
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', disableVerticalScroll);
    return () => {
      router.events.off('routeChangeComplete', disableVerticalScroll);
    };
  }, []);

  return (
    <Provider store={store}>
      <SessionWrapper>
        <ListenHistoryWrapper>
          <TopBar/>
          <Component {...pageProps} />
          <Footer/>
          <SideNav/>
        </ListenHistoryWrapper>
      </SessionWrapper>
    </Provider>
  );
}

function ListenHistoryWrapper(props: PropsWithChildren<{}>) {
  const dispatch = useDispatch();
  const [listenHistory] = useLocalStorage('listen_history', []);

  useEffect(() => {
    dispatch(loadListenHistoriesAction(listenHistory));
  }, []);

  return <React.Fragment>{props.children}</React.Fragment>;
}

function SessionWrapper(props: PropsWithChildren<{}>) {
  const session = useSession();

  useEffect(() => {
    session.syncSessionWithLS();
  }, []);

  return <React.Fragment>{props.children}</React.Fragment>;
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const recCode = appContext.ctx.query.rec_code as string;
  if (recCode)
    appendSetCookieHeader(appContext.ctx, 'rec_code', recCode,
      7 * 24 * 60 * 60);
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};

export default MyApp;
