import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSession from '../../hooks/useSession';
import { closeSideNavAction, openSideNavAction } from '../../redux/action';

function TopBar() {
  const [loginReturnUrl, setLoginReturnUrl] = useState('');
  const session = useSession();
  const dispatch = useDispatch();
  const isShowingMenu = useSelector<any, boolean>(r => r.SideNav.isOpened);
  const [isShowingQRCode, setShowingQRCode] = useState(false);

  useEffect(() => {
    setLoginReturnUrl(window.location.href);
  }, []);

  function handleDownloadClick() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
      .test(navigator.userAgent)) {
      window.open('https://download.hoost.tw/vA72?pid=hoost&c=header&utm_source=hoost&utm_medium=header&utm_campaign=download', '_blank').focus();
    } else {
      setShowingQRCode(i => !i);
    }
  }

  return (
    <div
      className="fixed top-0 w-full flex flex-row justify-center
     bg-grey-900 py-6 z-50"
    >
      <div
        className="container flex flex-row justify-between px-4 lg:px-8
      items-center"
      >
        <Link href="/">
          <img
            src={'/icons/hoost-logo.svg'}
            className="h-9 cursor-pointer"
            loading="lazy"
            alt=""
          />
        </Link>

        <div
          className="flex-row
         items-center hidden md:flex"
        >
          <div
            onClick={handleDownloadClick}
            className="flex flex-row items-center mr-6 cursor-pointer relative"
          >
            <img src={'/icons/qr-code.svg'} className="mr-2" alt=""/>
            <p className=" text-white font-bold">免費下載 Hoost</p>
            <div
              style={{ left: -48 }}
              className={`bg-white rounded-xl transition-all p-4 w-64
            absolute top-12` + (isShowingQRCode ? ' ' : ' hidden')}>
              <p className="text-center font-bold">掃描 QR Code 下載 App</p>
              <img
                src={'/images/qr-code.svg'}
                className="rounded-xl overflow-hidden mt-2"
                alt=""/>
            </div>
          </div>

          {isShowingQRCode && <div
            className="w-full h-full fixed top-0 left-0"
            onClick={() => setShowingQRCode(false)}/>}

          <p
            onClick={() => window.open('https://hoostcourse.typeform.com/to/ibLAtfu1', '_blank').focus()}
            className="border-transparent hover:border-white text-white
             mr-6 font-bold hidden lg:block cursor-pointer border-2 py-1 px-2
             rounded-xl transition-all"
          >
            在 Hoost 授課
          </p>

          <Link href={session.isLoggedIn ? '/user/topics' : '/login'}>
            <p
              className="border-transparent hover:border-white
            text-white cursor-pointer mr-6 font-bold border-2 py-1 px-2
            rounded-xl transition-all"
            >
              我的課程
            </p>
          </Link>

          {!session.isLoggedIn && <Link href={`/login?url=${loginReturnUrl}`}>
            <p
              onClick={() => (window.fbq ? window.fbq('track', 'Login') : {})}
              className="border-transparent hover:border-white
                 border-2 py-1 px-2 rounded-xl font-bold  text-white
                  cursor-pointer transition-all"
            >
                  登入
            </p>
          </Link>}

          {session.isLoggedIn && <p
            onClick={session.logout}
            className="border-transparent hover:border-white
            border-2 py-1 px-2 rounded-xl font-bold  text-white
            cursor-pointer transition-all"
          >
              登出
          </p>}

        </div>
        <div className="md:hidden">
          <img
            src={'/icons/hamberger-black.svg'}
            style={{
              float: 'right',
              cursor: 'pointer',
              width: 32,
              borderRadius: 8,
              backgroundColor: 'white',
              padding: 6,
            }}
            onClick={() => {
              dispatch(isShowingMenu
                ? closeSideNavAction()
                : openSideNavAction());
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default TopBar;
