import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import AppStateType from '../models/AppStateType';
import User from '../models/User';
import { loginAction, logoutAction } from '../redux/action';
import useLocalStorage from './useStorage';

function useSession() {
  const dispatch = useDispatch();

  const [tokenInLS, setTokenInLS, removeTokenInLS] = useLocalStorage(
    'authToken',
    null
  );
  const [userDataInLS, setUserDataInLS, removeUserDataInLS] = useLocalStorage(
    'userData',
    null
  );

  const user =
    process.browser &&
    useSelector<AppStateType, User | undefined>((s) => s.Session.userData);
  const token =
    process.browser &&
    useSelector<AppStateType, string | undefined>((s) => s.Session.authToken);

  const isLoggedIn = !!token;

  function login(token: string, data: User) {
    setTokenInLS(token);
    setUserDataInLS(data);
    Sentry.setUser(data);
    dispatch(loginAction(token, data));
  }

  function logout() {
    removeTokenInLS();
    removeUserDataInLS();
    dispatch(logoutAction());
    Sentry.configureScope((scope) => scope.setUser(null));

    // remove cookies
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  /*
   * 將 session state 保存至 local storage 以便下次進入頁面可以自動登入
   * 用於 OAuth 2.0 完成後的狀態保存
   */
  function saveSession(token: string, user: User) {
    window.localStorage.setItem('token', token);
    setTokenInLS(token);
    setUserDataInLS(user);
    dispatch(loginAction(token, user));
  }

  /*
   * 將 local storage 讀取至 session state
   * 用於頁面重新載入後的自動登入
   */
  function syncSessionWithLS() {
    if (tokenInLS && userDataInLS && typeof userDataInLS.id === 'string') {
      if (!document.cookie.includes('p-auth')) {
        removeTokenInLS();
        removeUserDataInLS();
        return false;
      }

      Sentry.setUser(userDataInLS);
      navigator?.serviceWorker?.controller?.postMessage({
        type: 'SYNC_SESSION',
        token: tokenInLS,
      });
      dispatch(loginAction(tokenInLS, userDataInLS));
      return true;
    }
    return false;
  }

  return {
    isLoggedIn,
    login,
    logout,
    user,
    token,
    saveSession,
    syncSessionWithLS,
  };
}

export default useSession;
