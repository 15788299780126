import { useEffect, useState } from 'react';

let evtTarget: any;

const useLocalStorage = (key: any, defaultValue: any) => {
  if (process.browser) {
    try {
      evtTarget = new EventTarget();
    } catch {
      evtTarget = document.createElement('phony');
    }

    const raw = process.browser ? localStorage.getItem(key) : '';

    let parsedRaw: any;
    try {
      parsedRaw = JSON.parse(raw);
    } catch (err) {
      localStorage['removeItem'](key);
    }

    const [value, setValue] = useState(parsedRaw ? parsedRaw : defaultValue);

    const updater = (updatedValue: any, remove = false) => {
      setValue(updatedValue);
      localStorage[remove ? 'removeItem' : 'setItem'](
        key,
        JSON.stringify(updatedValue)
      );
      evtTarget.dispatchEvent(
        new CustomEvent('storage_change', { detail: { key } })
      );
    };

    defaultValue != null && !raw && updater(defaultValue);

    useEffect(() => {
      const listener = ({ detail }: any) => {
        if (detail.key === key) {
          const lraw = localStorage.getItem(key);

          lraw !== raw && setValue(JSON.parse(lraw));
        }
      };

      evtTarget.addEventListener('storage_change', listener);
      return () => evtTarget.removeEventListener('storage_change', listener);
    });
    return [value, updater, () => updater(null, true)];
  }
  return [defaultValue, () => {}, () => {}];
};

export default useLocalStorage;
