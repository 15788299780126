import User from '../models/User';

export const OpenSideNavAction = 'OPEN_SIDENAV_ACTION';

interface OpenSideNavAction {
  type: typeof OpenSideNavAction;
}

export function openSideNavAction(): OpenSideNavAction {
  return { type: OpenSideNavAction };
}

export const CloseSideNavAction = 'CLOSE_SIDENAV_ACTION';

interface CloseSideNavAction {
  type: typeof CloseSideNavAction;
}

export function closeSideNavAction(): CloseSideNavAction {
  return { type: CloseSideNavAction };
}

export const EnableErrorAction = 'ENABLE_ERROR_ACTION';

interface EnableErrorAction {
  type: typeof EnableErrorAction;
}

export function enableErrorAction(): EnableErrorAction {
  return { type: EnableErrorAction };
}

export const DisableErrorAction = 'DISABLE_ERROR_ACTION';

interface DisableErrorAction {
  type: typeof DisableErrorAction;
}

export function disableErrorAction(): DisableErrorAction {
  return { type: DisableErrorAction };
}

export const LoginAction = 'LOGIN_ACTION';

interface LoginAction {
  type: typeof LoginAction;
  token: string;
  data: User;
}

export function loginAction(token: string, data: User): LoginAction {
  return { type: LoginAction, token, data };
}

export const LogoutAction = 'LOGOUT_ACTION';

interface LogoutAction {
  type: typeof LogoutAction;
}

export function logoutAction(): LogoutAction {
  return { type: LogoutAction };
}

export const ClearLoginResponseAction = 'CLEAR_LOGIN_RESPONSE_ACTION';

interface ClearLoginResponseAction {
  type: typeof ClearLoginResponseAction;
}

export function clearLoginResponseAction(): ClearLoginResponseAction {
  return { type: ClearLoginResponseAction };
}

export const UpdateListenHistoryAction = 'UPDATE_LISTEN_HISTORY_ACTION';

interface UpdateListenHistoryAction {
  type: typeof UpdateListenHistoryAction;
  episodeId: string;
  time: number;
  total: number;
}

export function updateListenHistory(
  episodeId: string,
  time: number,
  total: number
): UpdateListenHistoryAction {
  return { type: UpdateListenHistoryAction, episodeId, time, total };
}

export const LoadListenHistoriesAction = 'LOAD_LISTEN_HISTORIES_ACTION';

export interface LoadListenHistoriesAction {
  type: typeof LoadListenHistoriesAction;
  data: { episodeId: string; time: number; total: number }[];
}

export function loadListenHistoriesAction(
  data: { episodeId: string; time: number; total: number }[]
): LoadListenHistoriesAction {
  return { type: LoadListenHistoriesAction, data };
}

export type AppStateActionType =
  | OpenSideNavAction
  | CloseSideNavAction
  | EnableErrorAction
  | DisableErrorAction
  | LoginAction
  | LogoutAction
  | ClearLoginResponseAction
  | UpdateListenHistoryAction
  | LoadListenHistoriesAction;
