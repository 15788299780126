import AppStateType, { emptyState } from '../models/AppStateType';
import {
  AppStateActionType,
  ClearLoginResponseAction,
  CloseSideNavAction,
  DisableErrorAction,
  EnableErrorAction,
  LoadListenHistoriesAction,
  LoginAction,
  LogoutAction,
  OpenSideNavAction,
  UpdateListenHistoryAction,
} from './action';

export function reducer(
  state = emptyState,
  action: AppStateActionType
): AppStateType {
  switch (action.type) {
    case OpenSideNavAction:
      return {
        ...state,
        SideNav: { ...state.SideNav, isOpened: true },
      };
    case CloseSideNavAction:
      return {
        ...state,
        SideNav: { ...state.SideNav, isOpened: false },
      };
    case EnableErrorAction:
      return { ...state, ErrorPage: { ...state.ErrorPage, active: true } };
    case DisableErrorAction:
      return { ...state, ErrorPage: { ...state.ErrorPage, active: false } };
    case LoginAction:
      return {
        ...state,
        Session: { authToken: action.token, userData: action.data },
      };
    case LogoutAction:
      return {
        ...state,
        Session: { authToken: undefined, userData: undefined },
      };
    case ClearLoginResponseAction:
      return {
        ...state,
        LoginResponse: { ...state.LoginResponse, login: false },
      };
    case UpdateListenHistoryAction:
      return {
        ...state,
        ListenHistory: [
          ...state.ListenHistory.filter(
            (c) => c.episodeId !== action.episodeId
          ),
          {
            episodeId: action.episodeId,
            time: action.time,
            total: action.total,
          },
        ],
      };
    case LoadListenHistoriesAction:
      return { ...state, ListenHistory: action.data };
    default:
      return state;
  }
}
